 import React, {useCallback, useEffect, useState} from 'react';
import logo from '../resources/logo.svg';
import './App.css';
import EnvironmentGateway from "./environment/EnvironmentGateway";


function App() {
    const [environmentName, setEnvironmentName] = useState("")

    const getEnvironmentName = useCallback(async () => {
        if (environmentName === "") {
            const name = await EnvironmentGateway.getName();
            setEnvironmentName(name);
        }
    }, [environmentName]);

    useEffect(() => {
        getEnvironmentName();
    }, [getEnvironmentName])


    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <text>REA Manager</text>
                <text>Landing Portal</text>
                <text>{"\n"}</text>
                {environmentName !== 'prod' && <text style={{color: 'red'}}>{environmentName}</text>}
                <a href="/agent-portal/index.html">Agent Portal</a>
                <a href="/team-lead-portal/index.html">Team Lead Portal</a>
            </header>
        </div>
    );
}

export default App;
