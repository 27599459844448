export default class EnvironmentGateway {
    static async getName() {
        const response = await fetch(process.env.REACT_APP_REA_API_URL + "/environment/name", this.request());
        if (!response.ok)
            return "";
        return await response.text();
    }

    private static request() {
        return {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}